import ReactCountryFlag from "react-country-flag";
import { useTranslation } from "react-i18next";

const lngs = [
  { code: "en", flagCode: "gb", native: "English" },
  { code: "es", flagCode: "es", native: "Spanish" },
  { code: "zh", flagCode: "cn", native: "Chinese" },
];

export default function LanguageSwitcher() {
  const { i18n } = useTranslation();
  const handleTrans = (code) => {
    i18n.changeLanguage(code);
    document.documentElement.lang = i18n.language;
  };

  return (
    <div>
      {lngs.map((lng, i) => {
        const { code, flagCode } = lng;
        return (
          <button
            key={code}
            className="ml-2 text-lg"
            onClick={() => handleTrans(code)}>
            <ReactCountryFlag
              countryCode={flagCode}
              svg
            />
          </button>
        );
      })}
    </div>
  );
}
