import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import Backend from 'i18next-http-backend';

i18n
.use(initReactI18next)
.use(Backend)
.init({
  lng: 'en',
  debug: false,
  fallbackLng: 'en',
  interpolation: {
    escapeValue: false, // not needed for react as it escapes by default
  },
  ns: ['common', 'treatments', 'types', 'videos', 'configurations'],
  defaultNS: 'common',
  backend: {
    // for all available options read the backend's repository readme file
    loadPath: '/locales/{{lng}}/{{ns}}.json'
  }
});

export default i18n;