import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import logo from "./assets/img/logo_nikkiso.svg";
import LanguageSwitcher from "./components/LanguageSwitcher";
import { useTranslation } from "react-i18next";

export default function Header(props) {
  const isMenuOpen = props.isMenuOpen;
  const { i18n } = useTranslation(["common", "videos"]);

  useEffect(() => {
    document.documentElement.lang = i18n.language;
  });

  return (
    <header
      className={`App-header flex justify-between p-6 md:py-6 md:px-8 bg-white dark:bg-grey-900 ${
        isMenuOpen === true ? "menu-open" : ""
      }`}
    >
      <div className="flex items-center self-center md:w-3/12 menu-button">
        {isMenuOpen ? (
          <FontAwesomeIcon
            className="cursor-pointer dark:text-white"
            onClick={props.onMenuToggle}
            icon={["fal", "times"]}
          />
        ) : (
          <FontAwesomeIcon
            className="cursor-pointer dark:text-white"
            onClick={props.onMenuToggle}
            icon={["fal", "bars"]}
          />
        )}
        <Link className="ml-6 back-button" to="/videos">
          <FontAwesomeIcon
            className="cursor-pointer dark:text-white"
            icon={["fal", "arrow-left"]}
          />
        </Link>
      </div>
      <div className="md:w-6/12">
        <Link to="/videos">
          <img
            src={logo}
            className="w-32 mx-auto App-logo xl:w-auto"
            alt="logo"
          />
        </Link>
      </div>
      <div className="flex items-center self-center justify-end md:w-3/12 search-button">
        <div className="flex-shrink mr-8">
          <Link to="/search">
            <FontAwesomeIcon
              className="dark:text-white"
              icon={["fal", "search"]}
            />
          </Link>
        </div>
        <div className="flex-shrink -mt-1">
          <LanguageSwitcher />
        </div>
      </div>
    </header>
  );
}
