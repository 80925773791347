import { useEffect, useState } from "react";
import { motion } from "framer-motion";
import { fadeInLeft, fadeInUp, slow } from "./components/Animations.js";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";
import { useTranslation } from 'react-i18next';

export default function SupportContainer(props) {
  const [videos, setVideo] = useState([]);
	const { t } = useTranslation(['common', 'videos']);

  useEffect(() => {
    const retrievedObject = localStorage.getItem("saved-videos");
    const savedIds = JSON.parse(retrievedObject);
    let results = [];

    if (savedIds.length === 0) {
      results = [""];
    } else {
      results = savedIds;
    }

    const videos = t('videos', { ns: 'videos', returnObjects: true }).filter(video => results.includes(video.slug));
    setVideo(videos);
  }, [t]);

  useEffect(() => {
    document.title = t('savedVideos') + " | Nikkiso Aquarius+";
  });

  // Loop through
  return (
    <div className="w-full h-full p-6 md:p-8">
      <motion.h1
        initial="initial"
        animate="in"
        exit="out"
        variants={fadeInLeft}
        transition={slow} className="bg-clip-text text-transparent bg-gradient-to-r from-blue to-red text-xl md:text-2xl xl:text-3xl mb-8">
        {t('savedVideos')}
      </motion.h1>
      <div className="flex flex-wrap -mx-3">
        {videos.map((video, index) => (
          <motion.div
            initial="initial"
            animate="in"
            exit="out"
            variants={fadeInUp}
            transition={slow} className="mb-4 md:mb-8 w-full md:w-1/3 px-3" key={video.slug}>
            <div className="relative">
              <div className="absolute inset-0 bg-gradient-to-br from-blue to-red opacity-50 rounded"></div>
              <div className="relative overflow-hidden">
                <Link to={`/video/${video.slug}`}>
                  <FontAwesomeIcon
                    className="absolute bottom-4 left-4 text-4xl text-white z-50"
                    icon={["fas", "play-circle"]}
                  />
                  <div className="absolute inset-0 bg-gradient-to-br from-blue to-red opacity-50 rounded z-40"></div>
                  <img className="rounded" src={video.poster} alt={video.title} />
                </Link>
              </div>
            </div>
            <Link to={`/video/${video.slug}`}>
              <h3 className="mt-4 text-sm dark:text-grey-300">
                {video.title}
              </h3>
            </Link>
          </motion.div>
        ))}
      </div>
    </div>
  );
}
