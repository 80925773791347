import { useEffect } from "react";
import { motion } from "framer-motion";
import { fadeIn, slowDelay } from "./components/Animations.js";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";

import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/swiper.scss";
import SwiperCore, { Navigation, Mousewheel } from "swiper/core";

import AquariusSystem from "./assets/img/AquariusSystem.jpg";

import { useTranslation } from 'react-i18next';

SwiperCore.use([Navigation, Mousewheel]);

export default function TypeList() {
  const { t } = useTranslation(['common', 'treatments', 'types', 'videos']);

  useEffect(() => {
    document.title = t('bannerTitle') + " | Nikkiso Aquarius+";
  });

  useEffect(() => {
    document.body.classList.add("home");
  });

  useEffect(() => {
    return () => {
      document.body.classList.remove("home");
    };
  }, []);

  useEffect(() => {
    if (localStorage.getItem("saved-videos") === null) {
      localStorage.setItem("saved-videos", "[]");
    }
  }, []);

  useEffect(() => {
    const swipers = document.getElementsByClassName("swiper-wrapper");
    for (let i = 0; i < swipers.length; i++) {
      if (swipers[i].childElementCount > 0) {
        swipers[i].closest(".type-list").classList.add("flex");
      } else {
        swipers[i].closest(".type-list").classList.add("hidden");
      }
    }
  }, []);

  // Loop through
  return (
    <div>
      <motion.div className="w-full px-6 md:px-8"
        initial="initial"
        animate="in"
        exit="out"
        variants={fadeIn}
        transition={slowDelay}>
        <div className="w-full bg-gradient-to-br from-blue to-red rounded overflow-hidden mb-8 md:mb-10 p-8 md:p-12 relative">
          <h2 className="text-white text-2xl md:text-3xl xl:text-5xl mb-8 font-light relative z-10">  
            {t('bannerTitle')}
          </h2>
          <h3 className="text-lg md:text-xl xl:text-2xl text-white font-light relative z-10">
            {t('bannerText')}
          </h3>
          <img
            className="object-cover md:object-contain opacity-30 md:opacity-100 h-full absolute top-0 right-0 filter mix-blend-multiply"
            src={AquariusSystem}
            alt={t('bannerTitle')}
          />
        </div>
      </motion.div>      

      {t('types', { ns: 'types', returnObjects: true }).map((type) => (
        <motion.div className="flex flex-col mb-4 type-list"
        initial="initial"
        animate="in"
        exit="out"
        variants={fadeIn}
        transition={slowDelay}
        key={type.id}>
        <div className="flex justify-between content-center px-6 md:px-8 mb-4">
          <h2 className="bg-clip-text text-transparent bg-gradient-to-r from-blue to-red text-base md:text-xl xl:text-2xl whitespace-nowrap overflow-hidden overflow-ellipsis">
            {t(type.name)}
          </h2>
          <div className="flex">
            <div
              className="swiper-prev cursor-pointer text-lg hover:text-blue dark:text-grey-300 dark:hover:text-blue"
              id={`swiper-prev-${t(type.id)}`}
            >
              <FontAwesomeIcon icon={["fas", "arrow-circle-left"]} />
            </div>
            <div
              className="swiper-next ml-1 cursor-pointer text-lg hover:text-blue dark:text-grey-300 dark:hover:text-blue"
              id={`swiper-next-${t(type.id)}`}
            >
              <FontAwesomeIcon icon={["fas", "arrow-circle-right"]} />
            </div>
          </div>
        </div>
        <Swiper
          navigation={{
            prevEl: `#swiper-prev-${t(type.id)}`,
            nextEl: `#swiper-next-${t(type.id)}`,
          }}
          spaceBetween={24}
          mousewheel={false}
          speed={300}
          passiveListeners={true}
          centerInsufficientSlides={false}
          preloadImages={false}
          lazy={false}
          onSlideChange={() => console.log("slide change")}
          onSwiper={(swiper) => console.log(swiper)}
          slidesPerView={1.5}
          breakpoints={{
            // when window width is >= 480px
            768: {
              slidesPerView: 2.5,
              spaceBetween: 32,
            },
            // when window width is >= 480px
            1024: {
              slidesPerView: 3.5,
            },
            // when window width is >= 640px
            1400: {
              slidesPerView: 4.5,
            },
          }}
        >
          {t('videos', { ns: 'videos', returnObjects: true }).filter((video) => video.typeID === type.id).map((video, index) => (
            <SwiperSlide className="mb-4 md:mb-8" key={t(video.slug)}>
              <div className="relative">
                <div className="relative rounded overflow-hidden min-h-160">                   
                  <Link to={`/video/${t(video.slug)}`}>
                    <FontAwesomeIcon
                      className="absolute bottom-4 left-4 text-4xl text-white z-50"
                      icon={["fas", "play-circle"]}
                    />
                    <div className="absolute inset-0 bg-gradient-to-br from-blue to-red opacity-50 rounded z-40"></div>
                    <img src={t(video.poster)} alt={t(video.title)} />
                  </Link>
                </div>
              </div>
              <Link to={`/video/${t(video.slug)}`}>
                <h3 className="mt-4 text-sm dark:text-grey-300 whitespace-nowrap	overflow-hidden	overflow-ellipsis">
                  {index + 1}. {t(video.title)}
                </h3>
              </Link>
            </SwiperSlide>
          ))}
        </Swiper>
      </motion.div>
      ))}

      <motion.div className="flex flex-col mb-4 type-list" key="treat"
        initial="initial"
        animate="in"
        exit="out"
        variants={fadeIn}
        transition={slowDelay}>
        <div className="flex content-center px-6 md:px-8 mb-4">
          <h2 className="bg-clip-text text-transparent bg-gradient-to-r from-blue to-red flex-grow text-base md:text-xl xl:text-2xl whitespace-nowrap	overflow-hidden	overflow-ellipsis">
            {t('inTreatmentTitle')}
          </h2>
          <div
            className="swiper-prev cursor-pointer text-lg hover:text-blue dark:text-grey-300 dark:hover:text-blue"
            id={`swiper-prev-treatment`}
          >
            <FontAwesomeIcon icon={["fas", "arrow-circle-left"]} />
          </div>
          <div
            className="swiper-next ml-1 cursor-pointer text-lg hover:text-blue dark:text-grey-300 dark:hover:text-blue"
            id={`swiper-next-treatment`}
          >
            <FontAwesomeIcon icon={["fas", "arrow-circle-right"]} />
          </div>
        </div>
        <Swiper
          navigation={{
            prevEl: `#swiper-prev-treatment`,
            nextEl: `#swiper-next-treatment`,
          }}
          spaceBetween={24}
          mousewheel={false}
          speed={300}
          passiveListeners={true}
          centerInsufficientSlides={false}
          preloadImages={false}
          lazy={false}
          onSlideChange={() => console.log("slide change")}
          onSwiper={(swiper) => console.log(swiper)}
          slidesPerView={1.5}
          breakpoints={{
            // when window width is >= 480px
            768: {
              slidesPerView: 2.5,
              spaceBetween: 32,
            },
            // when window width is >= 480px
            1024: {
              slidesPerView: 3.5,
            },
            // when window width is >= 640px
            1400: {
              slidesPerView: 4.5,
            },
          }}
          className="in-treatment"
        >
          {t('treatments', { ns: 'treatments', returnObjects: true }).map((guide, index) => (
              <SwiperSlide className="mb-4 md:mb-8" key={t(guide.slug)}>
              <div className="relative">
                <div className="relative rounded overflow-hidden min-h-160">                   
                  <Link to={"/treatment/" + t(guide.slug)}>
                    <FontAwesomeIcon
                      className="absolute bottom-4 left-4 text-4xl text-white z-50"
                      icon={["fas", "play-circle"]}
                    />
                    <div className="absolute inset-0 bg-gradient-to-br from-blue to-red opacity-50 rounded z-40"></div>
                    <img src={t(guide.poster)} alt={t(guide.title)} />
                  </Link>
                </div>
              </div>
              <Link to={"/treatment/" + t(guide.slug)}>
                <h3 className="mt-4 text-sm dark:text-grey-300 whitespace-nowrap overflow-hidden	overflow-ellipsis">
                  {index + 1}. {t(guide.title)}
                </h3>
              </Link>
            </SwiperSlide>
          ))}
        </Swiper>
      </motion.div>
    </div>
  );
}
