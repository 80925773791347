import { useState } from 'react';
import { motion } from "framer-motion";
import { fadeInDown, slow } from "../components/Animations.js";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import MarkdownContainer from "../components/MarkdownContainer";
import LanguageSwitcher from '../components/LanguageSwitcher';
import { useTranslation } from 'react-i18next';

export default function ModalHome(props) {
	const [isVisible, setIsVisible] = useState(true);
  const { t } = useTranslation('common');

	return (
		<div className="modal fixed top-0 left-0 w-full h-screen backdrop-filter backdrop-blur-sm" style={{ display: isVisible ? "block" : "none" }}>
			<div className="absolute w-11/12 xl:w-7/12 z-50 top-8 md:top-1/2 left-1/2 transform -translate-x-1/2 md:-translate-y-1/2">
				<motion.div
					initial="initial"
					animate="in"
					exit="out"
					variants={fadeInDown}
					transition={slow} className="rounded p-8 md:p-12 pb-6 shadow-xl bg-white dark:bg-grey-900">

					<div className='flex justify-between items-baseline'>
						<h3 className="bg-clip-text text-transparent bg-gradient-to-r from-blue to-red mb-6 text-2xl">
							{t('disclaimerTitle')}
						</h3>

						<div className="flex-shrink -mt-1">
							<LanguageSwitcher />					
						</div>
					</div>
			
					<div>
						<div className="text-xxs md:text-xs xl:text-sm text-justify dark:text-grey-300">
							<MarkdownContainer markdown={t('disclaimerText')} />
						</div>
						<button className="button bg-blue hover:bg-black dark:hover:bg-red text-white py-2 px-4 inline-block font-semibold relative z-10 rounded focus:outline-none transition mb-6 mt-2" onClick={() => setIsVisible(false)}><FontAwesomeIcon className="cursor-pointer mr-2" icon={['fas', 'check-circle']} /> {t('disclaimerButton')}</button>
						<div className="text-xxs dark:text-grey-300">
							<MarkdownContainer markdown={t('disclaimerBrowser')} />
						</div>
					</div>			
				</motion.div>
			</div>
		</div>
	);
}
