import { useRef } from 'react';
import useLocalStorage from 'react-use-localstorage';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTranslation } from 'react-i18next';

export interface FavouriteProps {
	id: string
}

export const Favourite = ({
	id,
}: FavouriteProps): JSX.Element | null => {
	const [storageItem, setStorageItem] = useLocalStorage(
		'saved-videos',
		JSON.stringify([]),
	)
	const storagedArray = useRef(JSON.parse(storageItem))
	const isFavourited = storagedArray.current.includes(id)

	const handleToggleFavourite = (): void => {
		if (!isFavourited) {
			storagedArray.current.push(id)
			setStorageItem(JSON.stringify(storagedArray.current))
		} else {
		 const indexFavouritedId = storagedArray.current.indexOf(id)
			storagedArray.current.splice(indexFavouritedId, 1)
			setStorageItem(JSON.stringify(storagedArray.current))
		}
	}

	const { t } = useTranslation(['common']);

	return (
		<div>
		<button className={`self-center transform transition duration-300 hover:scale-105 font-bold whitespace-nowrap focus:outline-none text-sm py-2 px-3 text-white rounded ${isFavourited ? ('bg-red') : ('bg-blue')}`} onClick={handleToggleFavourite}>{isFavourited ? (
			<FontAwesomeIcon className="text-white mr-1" icon={['fas', 'heart']} />
		) : (
			<FontAwesomeIcon className="text-white mr-1" icon={['fal', 'heart']} />
		)} {t('saveButton')}</button>
		</div>
	)
}
