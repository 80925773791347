import { useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { motion } from "framer-motion";
import { fadeInUp, fadeInDown, slow } from "./components/Animations.js";
import { Link } from 'react-router-dom';
import aquariusLogo from './assets/img/aquarius-logo-white.svg';
import logo from './assets/img/logo_nikkiso-white.svg';
import { useTranslation } from 'react-i18next';
import LanguageSwitcher from './components/LanguageSwitcher';

export default function Loader() {
	const { t } = useTranslation(['common', 'videos']);

	useEffect(() => {
		document.body.classList.add("loader");
    document.title = t('bannerTitle') + " | Nikkiso Aquarius+";
	});

	useEffect(() => {
		return () => {
			document.body.classList.remove("loader");
		};
	}, []);

	// Loop through
	return (
		<div className="w-full h-full p-6 md:p-8 pb-12 md:pb-12 overflow-hidden relative">
			<div className="absolute top-8 right-8">
				<LanguageSwitcher />	
			</div>
			<div className="h-full flex flex-col items-center">
				<motion.img
					initial="initial"
					animate="in"
					exit="out"
					variants={fadeInDown}
					transition={slow} src={logo} className="App-logo mx-auto" alt="logo" />
				<div className="flex-grow items-center flex flex-col justify-center w-full">
					<Link className="w-full" to="/videos">
						<motion.img
							initial="initial"
							animate="in"
							exit="out"
							variants={fadeInUp}
							transition={slow} className="w-1/2 mx-auto mb-12" src={aquariusLogo} alt="aquarius" />
					</Link>
					<motion.div
					initial="initial"
					animate="in"
					exit="out"
					variants={fadeInUp}
					transition={slow}><Link className="button bg-white py-2 px-4 font-semibold relative z-10 rounded text-black text-xl" to="/videos">{t('startButton')}<FontAwesomeIcon className="ml-4" icon={['fas', 'arrow-circle-right']} /></Link></motion.div>
				</div>
			</div>
			<p className='text-white text-xs text-right'>PM-0225-09/2021-1 / V:31102022.2</p>
		</div>
	)
}
