import ReactMarkdown from 'react-markdown'
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter'
import { materialLight } from 'react-syntax-highlighter/dist/esm/styles/prism'
import rehypeRaw from 'rehype-raw'


export default function MarkdownContainer ({ markdown }) {
	const components = {
		code: ({ language, value }) => {
			return <SyntaxHighlighter style={materialLight} language={language} children={value} />
		}
	}

	return (
		<ReactMarkdown components={components} children={markdown} className='content' rehypePlugins={[rehypeRaw]} />
	)
}
