import { useEffect } from "react";
import { Link } from 'react-router-dom';
import { motion } from "framer-motion";
import { fadeInLeft, fadeInUp, slow } from "../components/Animations.js";
import { useTranslation } from 'react-i18next';

export default function ProgrammingGuide(props) {
	const { t } = useTranslation(['common', 'videos']);

  useEffect(() => {
    document.title = t('programmingGuide') + " | Nikkiso Aquarius+";
  });

	return (
		<section className="flex flex-wrap md:flex-1 border-t border-grey-200 dark:border-grey-800">
			<div className="w-full md:w-8/12 lg:w-3/4 p-6 md:p-8">
				<motion.h1
					initial="initial"
					animate="in"
					exit="out"
					variants={fadeInLeft}
					transition={slow} className="bg-clip-text text-transparent bg-gradient-to-r from-blue to-red text-xl md:text-2xl xl:text-3xl leading-tight mb-6">{t('programmingGuideContent')}</motion.h1>
				<div className="flex flex-col textContent">
					<motion.section className="section" id="guide"
						initial="initial"
						animate="in"
						exit="out"
						variants={fadeInUp}
						transition={slow}>
						<div className="w-full overflow-x-scroll">
							<table className="text-xs xl:text-sm mb-6 table w-full">
								<thead>
									<tr className="text-white text-center font-bold border-b-2 border-white dark:border-grey-900 table-row">
										<td className="table-cell bg-brown p-2 md:border-r-2 border-white dark:border-grey-900 rounded-tl">{t('programmingGuideThOne')}</td>
										<td className="table-cell bg-red p-2 md:border-r-2 border-white dark:border-grey-900">{t('programmingGuideThTwo')}</td>
										<td className="table-cell bg-orange-200 p-2 md:border-r-2 border-white dark:border-grey-900">{t('programmingGuideThThree')}</td>
										<td className="table-cell bg-green p-2 md:border-r-2 border-white dark:border-grey-900">{t('programmingGuideThFour')}</td>
										<td className="table-cell bg-green-500 p-2 md:border-r-2 border-white dark:border-grey-900">{t('programmingGuideThFive')}</td>
										<td className="table-cell bg-green-400 p-2 md:border-r-2 border-white dark:border-grey-900">{t('programmingGuideThSix')}</td>
										<td className="table-cell bg-red p-2 md:border-r-2 border-white dark:border-grey-900">{t('programmingGuideThSeven')}</td>
										<td className="table-cell bg-orange-200 p-2 rounded-tr">{t('programmingGuideThEight')}</td>
									</tr>
								</thead>
								<tbody>
									<tr className="border-b-2 border-white dark:border-grey-900 table-row">
										<td className="p-4 md:border-r-2 border-white dark:border-grey-900 bg-brown-100">40</td>
										<td className="p-4 md:border-r-2 border-white dark:border-grey-900 bg-red-100">150</td>
										<td className="p-4 md:border-r-2 border-white dark:border-grey-900 bg-orange-100">15</td>
										<td className="p-4 md:border-r-2 border-white dark:border-grey-900 bg-green-300">1500</td>
										<td className="p-4 md:border-r-2 border-white dark:border-grey-900 bg-green-200">1000</td>
										<td className="p-4 md:border-r-2 border-white dark:border-grey-900 bg-green-100">500</td>
										<td className="p-4 md:border-r-2 border-white dark:border-grey-900 bg-red-100">120</td>
										<td className="p-4 md:border-r-2 border-white dark:border-grey-900 bg-orange-100">19</td>
									</tr>
									<tr className="border-b-2 border-white dark:border-grey-900 table-row">
										<td className="p-4 md:border-r-2 border-white dark:border-grey-900 bg-brown-100">50</td>
										<td className="p-4 md:border-r-2 border-white dark:border-grey-900 bg-red-100">180</td>
										<td className="p-4 md:border-r-2 border-white dark:border-grey-900 bg-orange-100">15</td>
										<td className="p-4 md:border-r-2 border-white dark:border-grey-900 bg-green-300">1800</td>
										<td className="p-4 md:border-r-2 border-white dark:border-grey-900 bg-green-200">1200</td>
										<td className="p-4 md:border-r-2 border-white dark:border-grey-900 bg-green-100">600</td>
										<td className="p-4 md:border-r-2 border-white dark:border-grey-900 bg-red-100">140</td>
										<td className="p-4 md:border-r-2 border-white dark:border-grey-900 bg-orange-100">20</td>
									</tr>
									<tr className="border-b-2 border-white dark:border-grey-900 table-row">
										<td className="p-4 md:border-r-2 border-white dark:border-grey-900 bg-brown-100">60</td>
										<td className="p-4 md:border-r-2 border-white dark:border-grey-900 bg-red-100">210</td>
										<td className="p-4 md:border-r-2 border-white dark:border-grey-900 bg-orange-100">15</td>
										<td className="p-4 md:border-r-2 border-white dark:border-grey-900 bg-green-300">2100</td>
										<td className="p-4 md:border-r-2 border-white dark:border-grey-900 bg-green-200">1400</td>
										<td className="p-4 md:border-r-2 border-white dark:border-grey-900 bg-green-100">700</td>
										<td className="p-4 md:border-r-2 border-white dark:border-grey-900 bg-red-100">170</td>
										<td className="p-4 md:border-r-2 border-white dark:border-grey-900 bg-orange-100">19</td>
									</tr>
									<tr className="border-b-2 border-white dark:border-grey-900 table-row">
										<td className="p-4 md:border-r-2 border-white dark:border-grey-900 bg-brown-100">70</td>
										<td className="p-4 md:border-r-2 border-white dark:border-grey-900 bg-red-100">240</td>
										<td className="p-4 md:border-r-2 border-white dark:border-grey-900 bg-orange-100">16</td>
										<td className="p-4 md:border-r-2 border-white dark:border-grey-900 bg-green-300">2500</td>
										<td className="p-4 md:border-r-2 border-white dark:border-grey-900 bg-green-200">1700</td>
										<td className="p-4 md:border-r-2 border-white dark:border-grey-900 bg-green-100">800</td>
										<td className="p-4 md:border-r-2 border-white dark:border-grey-900 bg-red-100">200</td>
										<td className="p-4 md:border-r-2 border-white dark:border-grey-900 bg-orange-100">19</td>
									</tr>
									<tr className="border-b-2 border-white dark:border-grey-900 table-row">
										<td className="p-4 md:border-r-2 border-white dark:border-grey-900 bg-brown-100">80</td>
										<td className="p-4 md:border-r-2 border-white dark:border-grey-900 bg-red-100">260</td>
										<td className="p-4 md:border-r-2 border-white dark:border-grey-900 bg-orange-100">16</td>
										<td className="p-4 md:border-r-2 border-white dark:border-grey-900 bg-green-300">2800</td>
										<td className="p-4 md:border-r-2 border-white dark:border-grey-900 bg-green-200">1900</td>
										<td className="p-4 md:border-r-2 border-white dark:border-grey-900 bg-green-100">900</td>
										<td className="p-4 md:border-r-2 border-white dark:border-grey-900 bg-red-100">220</td>
										<td className="p-4 md:border-r-2 border-white dark:border-grey-900 bg-orange-100">19</td>
									</tr>
									<tr className="border-b-2 border-white dark:border-grey-900 table-row">
										<td className="p-4 md:border-r-2 border-white dark:border-grey-900 bg-brown-100">90</td>
										<td className="p-4 md:border-r-2 border-white dark:border-grey-900 bg-red-100">290</td>
										<td className="p-4 md:border-r-2 border-white dark:border-grey-900 bg-orange-100">17</td>
										<td className="p-4 md:border-r-2 border-white dark:border-grey-900 bg-green-300">3200</td>
										<td className="p-4 md:border-r-2 border-white dark:border-grey-900 bg-green-200">2100</td>
										<td className="p-4 md:border-r-2 border-white dark:border-grey-900 bg-green-100">1100</td>
										<td className="p-4 md:border-r-2 border-white dark:border-grey-900 bg-red-100">250</td>
										<td className="p-4 md:border-r-2 border-white dark:border-grey-900 bg-orange-100">19</td>
									</tr>
									<tr className="border-b-2 border-white dark:border-grey-900 table-row">
										<td className="p-4 md:border-r-2 border-white dark:border-grey-900 bg-brown-100">100</td>
										<td className="p-4 md:border-r-2 border-white dark:border-grey-900 bg-red-100">310</td>
										<td className="p-4 md:border-r-2 border-white dark:border-grey-900 bg-orange-100">17</td>
										<td className="p-4 md:border-r-2 border-white dark:border-grey-900 bg-green-300">3500</td>
										<td className="p-4 md:border-r-2 border-white dark:border-grey-900 bg-green-200">2300</td>
										<td className="p-4 md:border-r-2 border-white dark:border-grey-900 bg-green-100">1200</td>
										<td className="p-4 md:border-r-2 border-white dark:border-grey-900 bg-red-100">270</td>
										<td className="p-4 md:border-r-2 border-white dark:border-grey-900 bg-orange-100">20</td>
									</tr>
									<tr className="border-b-2 border-white dark:border-grey-900 table-row">
										<td className="p-4 md:border-r-2 border-white dark:border-grey-900 bg-brown-100">110</td>
										<td className="p-4 md:border-r-2 border-white dark:border-grey-900 bg-red-100">350</td>
										<td className="p-4 md:border-r-2 border-white dark:border-grey-900 bg-orange-100">17</td>
										<td className="p-4 md:border-r-2 border-white dark:border-grey-900 bg-green-300">3900</td>
										<td className="p-4 md:border-r-2 border-white dark:border-grey-900 bg-green-200">2600</td>
										<td className="p-4 md:border-r-2 border-white dark:border-grey-900 bg-green-100">1300</td>
										<td className="p-4 md:border-r-2 border-white dark:border-grey-900 bg-red-100">290</td>
										<td className="p-4 md:border-r-2 border-white dark:border-grey-900 bg-orange-100">20</td>
									</tr>
									<tr className="border-b-2 border-white dark:border-grey-900 table-row">
										<td className="p-4 md:border-r-2 border-white dark:border-grey-900 bg-brown-100">120</td>
										<td className="p-4 md:border-r-2 border-white dark:border-grey-900 bg-red-100">360</td>
										<td className="p-4 md:border-r-2 border-white dark:border-grey-900 bg-orange-100">18</td>
										<td className="p-4 md:border-r-2 border-white dark:border-grey-900 bg-green-300">4200</td>
										<td className="p-4 md:border-r-2 border-white dark:border-grey-900 bg-green-200">2800</td>
										<td className="p-4 md:border-r-2 border-white dark:border-grey-900 bg-green-100">1400</td>
										<td className="p-4 md:border-r-2 border-white dark:border-grey-900 bg-red-100">320</td>
										<td className="p-4 md:border-r-2 border-white dark:border-grey-900 bg-orange-100">20</td>
									</tr>
									<tr className="border-b-2 border-white dark:border-grey-900 table-row">
										<td className="p-4 md:border-r-2 border-white dark:border-grey-900 bg-brown-100">130</td>
										<td className="p-4 md:border-r-2 border-white dark:border-grey-900 bg-red-100">390</td>
										<td className="p-4 md:border-r-2 border-white dark:border-grey-900 bg-orange-100">18</td>
										<td className="p-4 md:border-r-2 border-white dark:border-grey-900 bg-green-300">4600</td>
										<td className="p-4 md:border-r-2 border-white dark:border-grey-900 bg-green-200">3000</td>
										<td className="p-4 md:border-r-2 border-white dark:border-grey-900 bg-green-100">1600</td>
										<td className="p-4 md:border-r-2 border-white dark:border-grey-900 bg-red-100">340</td>
										<td className="p-4 md:border-r-2 border-white dark:border-grey-900 bg-orange-100">20</td>
									</tr>
									<tr className="border-b-2 border-white dark:border-grey-900 table-row">
										<td className="p-4 md:border-r-2 border-white dark:border-grey-900 bg-brown-100">140</td>
										<td className="p-4 md:border-r-2 border-white dark:border-grey-900 bg-red-100">420</td>
										<td className="p-4 md:border-r-2 border-white dark:border-grey-900 bg-orange-100">18</td>
										<td className="p-4 md:border-r-2 border-white dark:border-grey-900 bg-green-300">4900</td>
										<td className="p-4 md:border-r-2 border-white dark:border-grey-900 bg-green-200">3200</td>
										<td className="p-4 md:border-r-2 border-white dark:border-grey-900 bg-green-100">1700</td>
										<td className="p-4 md:border-r-2 border-white dark:border-grey-900 bg-red-100">370</td>
										<td className="p-4 md:border-r-2 border-white dark:border-grey-900 bg-orange-100">20</td>
									</tr>
									<tr className="border-b-2 border-white dark:border-grey-900 table-row">
										<td className="p-4 md:border-r-2 border-white dark:border-grey-900 bg-brown-100">150</td>
										<td className="p-4 md:border-r-2 border-white dark:border-grey-900 bg-red-100">450</td>
										<td className="p-4 md:border-r-2 border-white dark:border-grey-900 bg-orange-100">18</td>
										<td className="p-4 md:border-r-2 border-white dark:border-grey-900 bg-green-300">5300</td>
										<td className="p-4 md:border-r-2 border-white dark:border-grey-900 bg-green-200">3500</td>
										<td className="p-4 md:border-r-2 border-white dark:border-grey-900 bg-green-100">1800</td>
										<td className="p-4 md:border-r-2 border-white dark:border-grey-900 bg-red-100">400</td>
										<td className="p-4 md:border-r-2 border-white dark:border-grey-900 bg-orange-100">20</td>
									</tr>
								</tbody>
							</table>
						</div>
						<p className="dark:text-grey-300"><small><sup>1</sup> Cole L et al High volume hemofiltration in human septic shock. (2001) Intensive Care Medicine 27(6) 978-986<br /><sup>2</sup> Teo B,W et al Chapter 15 Continuous Renal Replacement Therapies in Daugirdas J.T, Blake P.G, Ing T.S Handbook of Dialysis 5th Edition Wolters Kluwer: Philadelphia page 274</small></p>
					</motion.section>
				</div>
			</div>
			<div className="w-full md:w-4/12 lg:w-1/4 md:h-full p-6 md:p-8 bg-grey-100 border-l border-grey-200 dark:border-grey-800 dark:bg-grey-950 dark:text-grey-300">
				<div className="sticky top-10 text-xs xl:text-sm">
				<h3 className="mb-4">{t('Menu')}</h3>
					<nav className="mb-8">
						<ul className="nav leading-tight list-none">
							<li className="mb-2"><Link to="/configurations">{t('aquarius')}</Link></li>
							<li className="mb-2"><Link to="/treatment/main-screen">{t('main')}</Link></li>
							<li className="mb-2"><Link to="/treatment/programming-screen">{t('programming')}</Link></li>
							<li className="mb-2"><Link className="active" to="/treatment/programming-guide">{t('programmingGuide')}</Link></li>
							<li className="mb-2"><Link to="/treatment/management">{t('rca')}</Link></li>
						</ul>
					</nav>

					<h3 className="mb-4">{t('programmingGuideTitle')}</h3>
					{t('programmingGuideContent')}
				</div>
			</div>
		</section>
	)
}
