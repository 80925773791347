import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export default function FeedbackButton() {
	const { t } = useTranslation();

	return(
		<a className="cursor-pointer text-white fixed z-10 bottom-4 left-4 bg-red p-2 md:p-4 rounded-full" href="https://form.jotform.com/213485203221040" target="_blank" rel="noreferrer">
      <FontAwesomeIcon className="text-white mr-2 text-xl" icon={['fal', 'comment-smile']} />
      {t('feedbackButton')}
    </a>
	)
}
