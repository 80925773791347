export const fadeInLeft = {
	initial: {
		opacity: 0,
		x: "-100px",
	},
	in: {
		opacity: 1,
		x: 0,
	},
	out: {
		opacity: 0,
		x: "100px",
	}
};

export const fadeInRight = {
	initial: {
		opacity: 0,
		x: "100px",
	},
	in: {
		opacity: 1,
		x: 0,
	},
	out: {
		opacity: 0,
		x: "-100px",
	}
};

export const fadeInUp = {
	initial: {
		opacity: 0,
		y: "100px",
	},
	in: {
		opacity: 1,
		y: 0,
	},
	out: {
		opacity: 0,
		y: "-100px",
	}
};

export const fadeInDown = {
	initial: {
		opacity: 0,
		y: "-100px",
	},
	in: {
		opacity: 1,
		y: 0,
	},
	out: {
		opacity: 0,
		y: "100px",
	}
};

export const fadeInZoom = {
	initial: {
		opacity: 0,
		scale: 0.8,
	},
	in: {
		opacity: 1,
		scale: 1,
	},
	out: {
		opacity: 0,
		scale: 0.8,
	}
};

export const fadeIn = {
	initial: {
		opacity: 0,
	},
	in: {
		opacity: 1,
	},
	out: {
		opacity: 0,
	}
};

export const fast = {
	type: "spring",
	ease: "anticipate",
	duration: 0.5
};

export const slow = {
	type: "spring",
	ease: "anticipate",
	duration: 1
};

export const slowDelay = {
	type: "spring",
	ease: "anticipate",
	duration: 1,
	delay: 1,
};