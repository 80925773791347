import { useEffect, useRef } from "react";
import { motion } from "framer-motion";
import { fadeInLeft, fadeInUp, slow } from "../components/Animations.js";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import useOnScreen from "../components/useOnScreen";
import { useTranslation } from 'react-i18next';
import MarkdownContainer from "../components/MarkdownContainer";

export default function Management(props) {
  const preparationRef = useRef();
  const preparationVisible = useOnScreen(preparationRef);
  const monitoringRef = useRef();
  const monitoringVisible = useOnScreen(monitoringRef);
  const orangeRef = useRef();
  const orangeVisible = useOnScreen(orangeRef);
  const purpleRef = useRef();
  const purpleVisible = useOnScreen(purpleRef);
  const goldRef = useRef();
  const goldVisible = useOnScreen(goldRef);
  const calciumRef = useRef();
  const calciumVisible = useOnScreen(calciumRef);

  const { t } = useTranslation();

  useEffect(() => {
    document.title = t('rca') + " | Nikkiso Aquarius+";
  });

  return (
    <section className="flex flex-wrap md:flex-1 border-t border-grey-200 dark:border-grey-800">
      <div className="w-full md:w-8/12 lg:w-3/4 p-6 md:p-8">
        <motion.h1
          initial="initial"
          animate="in"
          exit="out"
          variants={fadeInLeft}
          transition={slow} className="bg-clip-text text-transparent bg-gradient-to-r from-blue to-red text-xl md:text-2xl xl:text-3xl leading-tight mb-6">
          {t('rca')}
        </motion.h1>
        <motion.div className="flex flex-col textContent dark:text-grey-300"
          initial="initial"
          animate="in"
          exit="out"
          variants={fadeInUp}
          transition={slow}>
          <h3 class="mb-4">{t('managementStart')}</h3>

          <section className="section text-sm has-markdown" id="preparation" ref={preparationRef}>
            <MarkdownContainer markdown={t('preparationContent')} />
          </section>

          <section className="section" id="monitoring" ref={monitoringRef}>
            <h4 className="mt-16 mb-8">
              <strong>{t('managementMonitoring')}</strong>
            </h4>
            <div className="flex flex-col md:flex-row -mx-2 items-stretch mb-24">
              <a
                className="bg-orange-light md:w-1/3 mb-2 md:mb-0 p-6 text-center rounded mx-2 text-orange text-base xl:text-xl font-bold transform hover:scale-105 transition duration-200"
                href="#orange"
              >
                {t('managementMonOne')}
              </a>
              <a
                className="bg-purple-light md:w-1/3 mb-2 md:mb-0 p-6 text-center rounded mx-2 text-purple text-base xl:text-xl font-bold transform hover:scale-105 transition duration-200"
                href="#purple"
              >
                {t('managementMonTwo')}
              </a>
              <a
                className="bg-gold-light md:w-1/3 p-6 text-center rounded mx-2 text-gold text-base xl:text-xl font-bold transform hover:scale-105 transition duration-200"
                href="#gold"
              >
                {t('managementMonThree')}
              </a>
            </div>

            <section className="section" id="orange" ref={orangeRef}>
              <h3 className="z-50 mb-8">
                <MarkdownContainer markdown={t('orangeTitle')} />
              </h3>

              <div className="relative bg-orange-light pt-4 mb-24 border border-orange rounded">
                <p className="transform -rotate-90 absolute left-4 top-full pl-6 origin-top-left text-orange text-sm xl:text-xl font-bold text-center">
                  <MarkdownContainer markdown={t('orangeTableXTitle')} />
                </p>
                <div className="ml-20 md:ml-24">
                  <table className="w-full text-xs md:text-sm xl-base">
                    <thead>
                      <tr>
                        <td className="mb-0 text-orange text-xs md:text-sm xl:text-xl font-bold uppercase pb-4">
                          {t('orangeTableYTitleOne')}
                        </td>
                        <td
                          colSpan="2"
                          className="mb-0 text-orange text-xs md:text-sm xl:text-xl text-center pb-4"
                        >
                          <MarkdownContainer markdown={t('orangeTableYTitleTwo')} />
                        </td>
                        <td className="relative text-center pb-4">
                          <div className="absolute -top-16 md:-top-20 left-1/2 transform -translate-x-1/2 scale-50 md:scale-100">
                            <div className="mx-auto text-center text-xxs text-white bg-orange border border-orange w-28 h-28 rounded-full leading-tight font-bold relative py-2">
                              <MarkdownContainer markdown={t('orangeTableCircle')} />
                            </div>
                            <span className="text-orange text-xxs font-bold">
                              {t('orangeTableFF')}
                            </span>
                          </div>
                        </td>
                      </tr>
                    </thead>
                    <tbody>
                      <tr className="text-center">
                        <td className="bg-orange text-white font-bold py-4 rounded-tl">
                          {t('orangeTableColOneTitle')}
                        </td>
                        <td className="bg-orange text-white font-bold py-4">
                          {t('orangeTableColTwoTitle')}
                        </td>
                        <td className="bg-orange text-white font-bold py-4">
                          {t('orangeTableColThreeTitle')}
                        </td>
                        <td className="bg-orange text-white font-bold py-4">
                          {t('orangeTableColFourTitle')}
                        </td>
                      </tr>
                      <tr className="text-center">
                        <td className="bg-grey-darker dark:bg-grey-800 py-4">&lt; 50</td>
                        <td className="bg-grey-darker dark:bg-grey-800 py-4">120</td>
                        <td className="bg-grey-darker dark:bg-grey-800 py-4">180</td>
                        <td className="bg-grey-darker dark:bg-grey-800 py-4">1400</td>
                      </tr>
                      <tr className="text-center">
                        <td className="bg-grey py-4 dark:bg-grey-900">50 - 59</td>
                        <td className="bg-grey py-4 dark:bg-grey-900">150</td>
                        <td className="bg-grey py-4 dark:bg-grey-900">230</td>
                        <td className="bg-grey py-4 dark:bg-grey-900">1800</td>
                      </tr>
                      <tr className="text-center">
                        <td className="bg-grey-darker dark:bg-grey-800 py-4">60 - 69</td>
                        <td className="bg-grey-darker dark:bg-grey-800 py-4">180</td>
                        <td className="bg-grey-darker dark:bg-grey-800 py-4">270</td>
                        <td className="bg-grey-darker dark:bg-grey-800 py-4">2100</td>
                      </tr>
                      <tr className="text-center">
                        <td className="bg-grey dark:bg-grey-900 py-4">70 - 79</td>
                        <td className="bg-grey dark:bg-grey-900 py-4">200</td>
                        <td className="bg-grey dark:bg-grey-900 py-4">300</td>
                        <td className="bg-grey dark:bg-grey-900 py-4">2400</td>
                      </tr>
                      <tr className="text-center">
                        <td className="bg-grey-darker dark:bg-grey-800 py-4">&gt; 80</td>
                        <td className="bg-grey-darker dark:bg-grey-800 py-4">230</td>
                        <td className="bg-grey-darker dark:bg-grey-800 py-4">350</td>
                        <td className="bg-grey-darker dark:bg-grey-800 py-4">2700</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </section>

            <section className="section" id="purple" ref={purpleRef}>
              <h3 className="z-50 mb-8">
                <MarkdownContainer markdown={t('purpleTitle')} />
              </h3>

              <div className="relative bg-purple-light pt-4 mb-24 border border-purple rounded">
                <p className="transform -rotate-90 absolute left-4 top-full pl-6 origin-top-left text-purple text-sm xl:text-xl font-bold text-center">
                  {t('purpleTableXTitle')}
                </p>
                <div className="ml-14 md:ml-24">
                  <table className="w-full text-xs md:text-sm xl-base">
                    <thead>
                      <tr>
                        <td className="mb-0 text-purple text-xs md:text-sm xl:text-xl font-bold uppercase pb-4">
                          {t('purpleTableYTitleOne')}
                        </td>
                        <td
                          colSpan="2"
                          className="mb-0 text-purple text-xs md:text-sm xl:text-xl text-center pb-4"
                        >
                          <MarkdownContainer markdown={t('purpleTableYTitleTwo')} />
                        </td>
                        <td className="relative text-center pb-4">
                          <div className="absolute -top-16 md:-top-20 left-1/2 transform -translate-x-1/2 scale-50 md:scale-100">
                            <div className="mx-auto text-center text-xxs text-white bg-purple border border-purple w-28 h-28 rounded-full leading-tight font-bold relative py-2">
                              <MarkdownContainer markdown={t('purpleTableCircle')} />
                            </div>
                            <span className="text-purple text-xxs font-bold">
                              {t('purpleTableFF')}
                            </span>
                          </div>
                        </td>
                      </tr>
                    </thead>
                    <tbody>
                      <tr className="text-center">
                        <td className="bg-purple text-white font-bold py-4 rounded-tl">
                          {t('purpleTableColOneTitle')}
                        </td>
                        <td className="bg-purple text-white font-bold py-4">
                          {t('purpleTableColTwoTitle')}
                        </td>
                        <td className="bg-purple text-white font-bold py-4">
                          {t('purpleTableColThreeTitle')}
                        </td>
                        <td className="bg-purple text-white font-bold py-4">
                          {t('purpleTableColFourTitle')}
                        </td>
                      </tr>
                      <tr className="text-center">
                        <td className="bg-grey-darker dark:bg-grey-800 py-4">&lt; 50</td>
                        <td className="bg-grey-darker dark:bg-grey-800 py-4">100</td>
                        <td className="bg-grey-darker dark:bg-grey-800 py-4">150</td>
                        <td className="bg-grey-darker dark:bg-grey-800 py-4">1100</td>
                      </tr>
                      <tr className="text-center">
                        <td className="bg-grey py-4 dark:bg-grey-900">50 - 59</td>
                        <td className="bg-grey py-4 dark:bg-grey-900">110</td>
                        <td className="bg-grey py-4 dark:bg-grey-900">170</td>
                        <td className="bg-grey py-4 dark:bg-grey-900">1300</td>
                      </tr>
                      <tr className="text-center">
                        <td className="bg-grey-darker dark:bg-grey-800 py-4">60 - 69</td>
                        <td className="bg-grey-darker dark:bg-grey-800 py-4">130</td>
                        <td className="bg-grey-darker dark:bg-grey-800 py-4">200</td>
                        <td className="bg-grey-darker dark:bg-grey-800 py-4">1500</td>
                      </tr>
                      <tr className="text-center">
                        <td className="bg-grey py-4 dark:bg-grey-900">70 - 79</td>
                        <td className="bg-grey py-4 dark:bg-grey-900">140</td>
                        <td className="bg-grey py-4 dark:bg-grey-900">210</td>
                        <td className="bg-grey py-4 dark:bg-grey-900">1700</td>
                      </tr>
                      <tr className="text-center">
                        <td className="bg-grey-darker dark:bg-grey-800 py-4">&gt; 80</td>
                        <td className="bg-grey-darker dark:bg-grey-800 py-4">160</td>
                        <td className="bg-grey-darker dark:bg-grey-800 py-4">240</td>
                        <td className="bg-grey-darker dark:bg-grey-800 py-4">1900</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </section>

            <section className="section" id="gold" ref={goldRef}>
              <h3 className="z-50 mb-8">
                <MarkdownContainer markdown={t('goldTitle')} />
              </h3>

              <div className="relative bg-gold-light pt-4 mb-12 border border-gold rounded">
                <p className="transform -rotate-90 absolute left-4 top-full pl-6 origin-top-left text-gold text-sm xl:text-xl font-bold text-center leading-none">
                  <MarkdownContainer markdown={t('goldTableXTitle')} />
                </p>
                <div className="ml-20 md:ml-24">
                  <table className="w-full text-xs md:text-sm xl-base">
                    <thead>
                      <tr>
                        <td className="mb-0 text-gold text-xs md:text-sm xl:text-xl font-bold uppercase pb-4">
                          {t('goldTableYTitleOne')}
                        </td>
                        <td
                          colSpan="2"
                          className="mb-0 text-gold text-xs md:text-sm xl:text-xl text-center pb-4"
                        >
                          <MarkdownContainer markdown={t('goldTableYTitleTwo')} />
                        </td>
                        <td className="relative text-center pb-4">
                          <div className="absolute -top-16 md:-top-20 left-1/2 transform -translate-x-1/2 scale-50 md:scale-100">
                            <div className="mx-auto text-center text-xxs text-white bg-gold border border-gold w-28 h-28 rounded-full leading-tight font-bold relative py-2">
                              <MarkdownContainer markdown={t('goldTableCircle')} />
                            </div>
                            <span className="text-gold text-xxs font-bold">
                              <MarkdownContainer markdown={t('goldTableFF')} />
                            </span>
                          </div>
                        </td>
                      </tr>
                    </thead>
                    <tbody>
                      <tr className="text-center">
                        <td className="bg-gold text-white font-bold py-4 rounded-tl">
                          {t('goldTableColOneTitle')}
                        </td>
                        <td className="bg-gold text-white font-bold py-4">
                          {t('goldTableColTwoTitle')}
                        </td>
                        <td className="bg-gold text-white font-bold py-4">
                          {t('goldTableColThreeTitle')}
                        </td>
                        <td className="bg-gold text-white font-bold py-4">
                          {t('goldTableColFourTitle')}
                        </td>
                      </tr>
                      <tr className="text-center text-red font-bold">
                        <td className="bg-grey-darker dark:bg-grey-800 py-4">&lt; 50</td>
                        <td colSpan="3" className="bg-grey-darker dark:bg-grey-800 py-4">
                          <MarkdownContainer markdown={t('goldTableMin')} />
                        </td>
                      </tr>
                      <tr className="text-center">
                        <td className="bg-grey dark:bg-grey-900 py-4">50 - 59</td>
                        <td className="bg-grey dark:bg-grey-900 py-4">80</td>
                        <td className="bg-grey dark:bg-grey-900 py-4">120</td>
                        <td className="bg-grey dark:bg-grey-900 py-4">1000</td>
                      </tr>
                      <tr className="text-center">
                        <td className="bg-grey-darker dark:bg-grey-800 py-4">60 - 69</td>
                        <td className="bg-grey-darker dark:bg-grey-800 py-4">100</td>
                        <td className="bg-grey-darker dark:bg-grey-800 py-4">150</td>
                        <td className="bg-grey-darker dark:bg-grey-800 py-4">1100</td>
                      </tr>
                      <tr className="text-center">
                        <td className="bg-grey dark:bg-grey-900 py-4">70 - 79</td>
                        <td className="bg-grey dark:bg-grey-900 py-4">120</td>
                        <td className="bg-grey dark:bg-grey-900 py-4">180</td>
                        <td className="bg-grey dark:bg-grey-900 py-4">1300</td>
                      </tr>
                      <tr className="text-center">
                        <td className="bg-grey-darker dark:bg-grey-800 py-4">&gt; 80</td>
                        <td className="bg-grey-darker dark:bg-grey-800 py-4">130</td>
                        <td className="bg-grey-darker dark:bg-grey-800 py-4">200</td>
                        <td className="bg-grey-darker dark:bg-grey-800 py-4">1500</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </section>

            <div className="flex flex-col md:flex-row mb-6">
              <h3 className="mr-4 text-teal">
                <strong>{t('managementMonitoring')}</strong>
              </h3>
              <h3>
                <MarkdownContainer markdown={t('managementMonitoringAfter')} />
              </h3>
            </div>
            <h4 className="mb-8">
              <strong>{t('managementOngoing')}</strong>
            </h4>
            <div className="flex flex-col md:flex-row -mx-2 items-stretch mb-6">
              <a
                className="bg-purple-light md:w-1/3 p-6 text-center rounded mx-2 text-purple text-base xl:text-xl font-bold"
                href="#purple"
              >
               <MarkdownContainer markdown={t('managementMonitoringWorse')} />
              </a>
            </div>
          </section>

          <section className="section has-markdown" id="calcium" ref={calciumRef}>
            <h4 className="mb-8">
              <strong>{t('calciumTitle')}</strong>
            </h4>
            <table className="text-xs xl:text-sm mb-12 flex flex-col md:table">
              <thead>
                <tr className="text-white font-bold border-b-2 border-white dark:border-grey-900 flex flex-col md:table-row">
                  <td className="block md:table-cell"></td>
                  <td className="block md:table-cell bg-blue p-4 rounded-tl md:border-r-2 border-white dark:border-grey-900">
                    <MarkdownContainer markdown={t('calciumColOneTitle')} />
                  </td>
                  <td className="block md:table-cell bg-blue p-4 md:border-r-2 border-white dark:border-grey-900">
                    <MarkdownContainer markdown={t('calciumColTwoTitle')} />
                  </td>
                  <td className="block md:table-cell bg-blue p-4 rounded-tr">
                    <MarkdownContainer markdown={t('calciumColThreeTitle')} />
                  </td>
                </tr>
              </thead>
              <tbody>
                <tr className="border-b-2 border-white dark:border-grey-900 flex flex-col md:table-row">
                  <td className="py-4 md:py-0 pr-4">
                    <FontAwesomeIcon
                      className="text-red text-lg"
                      icon={["fal", "traffic-light-stop"]}
                    />
                  </td>
                  <td className="bg-orange bg-opacity-30 p-4 md:border-r-2 border-white dark:border-grey-900">
                    &lt; 0.8
                  </td>
                  <td className="bg-orange bg-opacity-30 p-4 md:border-r-2 border-white dark:border-grey-900">
                    <MarkdownContainer markdown={t('calciumColTwoRowOne')} />
                  </td>
                  <td className="bg-orange bg-opacity-30 p-4">
                    {t('calciumColThreeRowOne')}
                  </td>
                </tr>
                <tr className="border-b-2 border-white dark:border-grey-900 flex flex-col md:table-row">
                  <td className="py-4 md:py-0 pr-4">
                    <FontAwesomeIcon
                      className="text-gold text-lg"
                      icon={["fal", "traffic-light-slow"]}
                    />
                  </td>
                  <td className="bg-gold bg-opacity-30 p-4 md:border-r-2 border-white dark:border-grey-900">
                    0.8 - 0.89
                  </td>
                  <td className="bg-gold bg-opacity-30 p-4 md:border-r-2 border-white dark:border-grey-900">
                    <MarkdownContainer markdown={t('calciumColTwoRowTwo')} />
                  </td>
                  <td className="bg-gold bg-opacity-30 p-4">
                    {t('calciumColThreeRowTwo')}
                  </td>
                </tr>
                <tr className="border-b-2 border-white dark:border-grey-900 flex flex-col md:table-row">
                  <td className="py-4 md:py-0 pr-4">
                    <FontAwesomeIcon
                      className="text-gold text-lg"
                      icon={["fal", "traffic-light-slow"]}
                    />
                  </td>
                  <td className="bg-gold bg-opacity-30 p-4 md:border-r-2 border-white dark:border-grey-900">
                    0.9 - 0.99
                  </td>
                  <td className="bg-gold bg-opacity-30 p-4 md:border-r-2 border-white dark:border-grey-900">
                    <MarkdownContainer markdown={t('calciumColTwoRowThree')} />
                  </td>
                  <td className="bg-gold bg-opacity-30 p-4">
                    {t('calciumColThreeRowThree')}
                  </td>
                </tr>
                <tr className="border-b-2 border-white dark:border-grey-900 flex flex-col md:table-row">
                  <td className="py-4 md:py-0 pr-4">
                    <FontAwesomeIcon
                      className="text-green text-lg"
                      icon={["fal", "traffic-light-slow"]}
                    />
                  </td>
                  <td className="bg-green bg-opacity-30 p-4 md:border-r-2 border-white dark:border-grey-900">
                    1.0 - 1.2
                  </td>
                  <td className="bg-green bg-opacity-30 p-4 md:border-r-2 border-white dark:border-grey-900">
                    <MarkdownContainer markdown={t('calciumColTwoRowFour')} />
                  </td>
                  <td className="bg-green bg-opacity-30 p-4 md:border-r-2 border-white dark:border-grey-900">
                    {t('calciumColThreeRowFour')}
                  </td>
                </tr>
                <tr className="border-b-2 border-white dark:border-grey-900 flex flex-col md:table-row">
                  <td className="py-4 md:py-0 pr-4">
                    <FontAwesomeIcon
                      className="text-gold text-lg"
                      icon={["fal", "traffic-light-slow"]}
                    />
                  </td>
                  <td className="bg-gold bg-opacity-30 p-4 md:border-r-2 border-white dark:border-grey-900">
                    &gt; 1.2
                  </td>
                  <td className="bg-gold bg-opacity-30 p-4 md:border-r-2 border-white dark:border-grey-900">
                    <MarkdownContainer markdown={t('calciumColTwoRowFive')} />
                  </td>
                  <td className="bg-gold bg-opacity-30 p-4 md:border-r-2 border-white dark:border-grey-900">
                    {t('calciumColThreeRowFive')}
                  </td>
                </tr>
              </tbody>
            </table>

            <div className="flex flex-col md:flex-row mb-6">
              <h3 className="mr-4 text-teal">
                <strong>{t('managementMonitoring')}</strong>
              </h3>
              <div>
                <h3>
                  <strong>
                    <MarkdownContainer markdown={t('managementMonitoringHours')} />
                  </strong>
                  <br />
                  <MarkdownContainer markdown={t('managementMonitoringLab')} />
                </h3>
                <p>
                  <small>
                    <MarkdownContainer markdown={t('managementMonitoringSups')} />
                  </small>
                </p>
              </div>
            </div>
            <p className="mb-6"><small>{t('pocket')}<br />
              Kirwan, C. J. et al, Implementation of a Simplified Regional Citrate Anticoagulation Protocol for Post-Dilution Continuous Hemofiltration Using a Bicarbonate Buffered, Calcium Containing Replacement Solution. Blood Purif 2016;42:349–355,
              DOI: 10.1159/000452755 “Free Access” at <a href="https://www.karger.com/Article/Pdf/452755" target="_blank" rel="noreferrer">https://www.karger.com/Article/Pdf/452755</a></small></p>

            <p className="mb-6"><small>Prowle J. R., Royal London Hospital Pilot Regional Citrate Anticoagulation Protocol for  Aquarius CRRT Platform. Researchgate September 2015 DOI: 10.13140/RG.2.1.2400.5600</small></p>

            <p className="mb-24"><small>Prowle J.R, Kirwan C.J, RLH CRRT Clinical Guide Including RCA July 2016. ResearchGate  September 2018 <a href="https://www.researchgate.net/publication/305546825_RLH_CRRT_" target="_blank" rel="noreferrer">https://www.researchgate.net/publication/305546825_RLH_CRRT_</a> Clinical_Guide_Including_RCA_July_2016 (Accessed November 04, 2021)</small></p>
          </section>
        </motion.div>
      </div>
      <div className="w-full md:w-4/12 lg:w-1/4 md:h-full p-6 md:p-8 bg-grey-100 border-l border-grey-200 dark:border-grey-800 dark:bg-grey-950 dark:text-grey-300">
        <div className="sticky top-10 text-xs xl:text-sm">

          <h3 className="mb-4">{t('menu')}</h3>
					<nav className="mb-8">
						<ul className="nav leading-tight list-none">
							<li className="mb-2"><Link to="/configurations">{t('aquarius')}</Link></li>
							<li className="mb-2"><Link to="/treatment/main-screen">{t('main')}</Link></li>
							<li className="mb-2"><Link to="/treatment/programming-screen">{t('programming')}</Link></li>
							<li className="mb-2"><Link to="/treatment/programming-guide">{t('programmingGuide')}</Link></li>
							<li className="mb-2"><Link className="active" to="/treatment/management">{t('rca')}</Link></li>
						</ul>
					</nav>

          <h3 className="mb-4">{t('managementTitle')}</h3>
					{t('managementContent')}

          <h3 className="mt-8 mb-4">{t('managementSections')}</h3>
          <nav>
            <ul className="nav leading-loose list-none mb-12">
              <li>
                <a
                  className={preparationVisible ? `active` : null}
                  href="#preparation"
                >
                {t('managementPrep')}
                </a>
              </li>
              <li>
                <a
                  className={monitoringVisible ? `active` : null}
                  href="#monitoring"
                >
                {t('managementMonitoring')}
                </a>
                <ul className="text-xs">
                  <li className="text-orange">
                    <a
                      className={orangeVisible ? `active` : null}
                      href="#orange"
                    >
                    {t('managementMonOne')}
                    </a>
                  </li>
                  <li className="text-purple">
                    <a
                      className={purpleVisible ? `active` : null}
                      href="#purple"
                    >
                    {t('managementMonTwo')}
                    </a>
                  </li>
                  <li className="text-gold">
                    <a className={goldVisible ? `active` : null} href="#gold">
                    {t('managementMonThree')}
                    </a>
                  </li>
                </ul>
              </li>
              <li>
                <a className={calciumVisible ? `active` : null} href="#calcium">
                {t('managementCalcium')}
                </a>
              </li>
            </ul>
          </nav>
        </div>
      </div>
    </section>
  );
}
