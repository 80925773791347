import { React, useEffect, useRef } from "react";
import { useParams } from "react-router-dom";

import VideoJS from './VideoJS';
import { motion } from "framer-motion";
import { fadeIn, fadeInUp, slowDelay } from "./components/Animations.js";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";

import MarkdownContainer from "./components/MarkdownContainer";
import { Favourite } from "./components/Favourite";
import { Offline, Online } from "react-detect-offline";

import { useTranslation } from 'react-i18next';

export default function VideoContainer(props) {
  const { slug } = useParams();
  const { t } = useTranslation(['common', 'videos']);
  const video = t('videos', { ns: 'videos', returnObjects: true }).filter((video) => video.slug === slug);

  useEffect(() => {
    document.title = t(video[0].title) + " | Nikkiso Aquarius+";
    console.log(video[0]);
  }, [t, video]);

  const playerRef = useRef();

  const videoJsOptions = {
    poster: t(video[0].poster),
    autoplay: true,
    loop: false,
    muted: true,
    controls: true,
    controlBar: {
      'liveDisplay': true,
      'pictureInPictureToggle': false,
      'volumePanel': false,
    },
    responsive: true,
    fluid: true,
    sources: [{
      src: t(video[0].video),
      type: 'video/mp4'
    }]
  }

  const handlePlayerReady = (player) => {
    playerRef.current = player;

    // you can handle player events here
    player.on('waiting', () => {
      console.log('player is waiting');
    });

    player.on('dispose', () => {
      console.log('player will dispose');
    });
  };

  return (      
    <div>
      {t('videos', { ns: 'videos', returnObjects: true }).filter((video) => video.slug === slug).map((video, index) => (
        <section className="flex flex-wrap md:flex-1" key={t(video.slug)}>
          <div className="w-full relative">

            <div className="releative p-6 md:p-8 lg:p-12 xl:p-16 bg-grey-100 dark:bg-grey-950">
              <Offline>
                <div className="bg-blue p-8 rounded text-white text-center max-w-7xl mx-auto shadow-xl">To view the video, please connect to the internet.</div>
              </Offline>
              <Online>
                <motion.div
                  initial="initial"
                  animate="in"
                  exit="out"
                  variants={fadeIn}
                  transition={slowDelay} className="relative rounded overflow-hidden shadow-xl max-w-7xl mx-auto">
                  <VideoJS options={videoJsOptions} onReady={handlePlayerReady} />
                </motion.div>
              </Online>
            </div>

            <motion.div className="p-6 md:p-8 md:pb-0 lg:p-12 lg:pb-0 xl:p-16 xl:pb-0 flex justify-between max-w-7xl mx-auto"
              initial="initial"
              animate="in"
              exit="out"
              variants={fadeInUp}
              transition={slowDelay}>
              <h1 className="text-xl md:text-2xl xl:text-3xl bg-clip-text text-transparent bg-gradient-to-r from-blue to-red mr-12 leading-tight">
                {video.title}
              </h1>
              <Favourite id={t(video.slug)} title={t(video.title)} />
            </motion.div>
            <motion.div className="flex flex-col md:flex-row textContent text-sm p-6 md:p-8 lg:p-12 xl:p-16 md:justify-between md:items-end dark:text-grey-300 max-w-7xl mx-auto"
              initial="initial"
              animate="in"
              exit="out"
              variants={fadeInUp}
              transition={slowDelay}>
              <MarkdownContainer markdown={t(video.content)} />
              <div class="md:ml-8 mt-8 md:mt-0">
              {video.nextVideo === null ? (
                <Link to={`/videos`}>
                  <span className="bg-red inline-block text-white py-2 px-4 font-bold hover:pr-6 transition-all duration-200 rounded self-end whitespace-nowrap">
                  {t('homeButton', { ns: 'common' })}
                  <FontAwesomeIcon
                  className="text-white ml-4"
                  icon={["fal", "arrow-right"]}
                  />
                  </span>
                </Link>
              ) : (
                <Link to={`/video/${t(video.nextVideo)}`}>
                  <span className="bg-red inline-block text-white py-2 px-4 font-bold hover:pr-6 transition-all duration-200 rounded self-end whitespace-nowrap">
                  {t('nextButton', { ns: 'common' })}
                  <FontAwesomeIcon
                  className="text-white ml-4"
                  icon={["fal", "arrow-right"]}
                  />
                  </span>
                </Link>
              )}
              </div>
            </motion.div>
          </div>
        </section>
      ))}
    </div>
  );
}
