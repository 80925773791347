import { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { motion } from "framer-motion";
import { fadeInLeft, fadeInUp, slow } from "./components/Animations.js";
import ReactCountryFlag from "react-country-flag";
import map from "./assets/img/map.svg";
import { useTranslation } from 'react-i18next';

export default function SupportContainer() {
  const [isVisible, setIsVisible] = useState(true);

  const { t } = useTranslation(['common', 'distributors']);

  useEffect(() => {
    document.title = t('support') + " | Nikkiso Aquarius+";
  });

  const [countrySelected, setSelected] = useState("Select");

  const handleChange = (e) => {
    setSelected(e.target.value);
  };

  let filteredContacts = t('distributors', { ns: 'distributors', returnObjects: true });

  if (countrySelected !== "All") {
    filteredContacts = t('distributors', { ns: 'distributors', returnObjects: true }).filter((distributor) => distributor.Country === countrySelected);
  }

  const twoCalls = (e) => {
    handleChange(e);
    setIsVisible(false);
  };

  // Loop through
  return (
    <div className="relative w-full h-full p-6 overflow-hidden md:p-8 bg-gradient-to-br from-blue to-red">
      <motion.h1
        initial="initial"
        animate="in"
        exit="out"
        variants={fadeInLeft}
        transition={slow} className="mb-8 text-xl text-white md:text-2xl xl:text-3xl">{t('support')}</motion.h1>
      <div style={{ display: isVisible ? "block" : "none" }}>
        <div className="relative flex flex-col h-full textContent ">
          <div className="z-50 w-full md:w-2/3 xl:w-1/3">
            <motion.div
              initial="initial"
              animate="in"
              exit="out"
              variants={fadeInUp}
              transition={slow} className="flex items-center justify-between p-3 mb-4 text-sm bg-white rounded shadow dark:bg-grey-900 dark:text-grey-300">
              <span>{t('country')}</span>
              <FontAwesomeIcon
                className="text-blue"
                icon={["fas", "angle-down"]}
              />
            </motion.div>
            <motion.div
              initial="initial"
              animate="in"
              exit="out"
              variants={fadeInUp}
              transition={slow} className="flex flex-col w-full p-2 overflow-scroll bg-white rounded shadow-lg dark:bg-grey-900 h-72 bg-opacity-60 backdrop-filter backdrop-blur-sm">
              {t('distributors', { ns: 'distributors', returnObjects: true }).map((distributor) => (
                <div className="w-full"><button
                  className="flex items-center justify-between w-full px-3 py-2 mb-2 text-sm text-left rounded hover:bg-blue hover:text-white focus:outline-none dark:text-grey-300"
                  value={distributor.Country}
                  onClick={twoCalls}
                  key={distributor.CountryCode}
                >
                  {distributor.Country}{" "}
                  <ReactCountryFlag countryCode={distributor.FlagCode} />
                </button></div>
              ))}
            </motion.div>
          </div>
        </div>
      </div>
      <div className="flex flex-wrap">
        {filteredContacts.map((distributor, id) => (
          <motion.div
            initial="initial"
            animate="in"
            exit="out"
            variants={fadeInUp}
            transition={slow}
            className="z-50 w-full p-6 mb-8 bg-white rounded shadow-lg md:w-2/3 xl:w-1/3 dark:bg-grey-900 backdrop-filter backdrop-blur-sm"
            key={distributor.CountryCode}
          >
            <h2 className="flex-grow mb-4 text-base text-blue md:text-2xl whitespace-nowrap overflow-hiddenoverflow-ellipsis">
              <ReactCountryFlag countryCode={distributor.FlagCode} />{" "}
              {distributor.Country}
            </h2>
            {distributor.Name ? (
              <p className="my-1 text-sm dark:text-grey-300">
                <FontAwesomeIcon
                  className="mr-2 text-blue"
                  icon={["fas", "building"]}
                />{" "}
                {distributor.Name}
              </p>
            ) : null}
            {distributor.ContactOne ? (
              <p className="my-1 text-sm dark:text-grey-300">
                <FontAwesomeIcon
                  className="mr-2 text-blue"
                  icon={["fas", "user"]}
                />{" "}
                {distributor.ContactOne}
              </p>
            ) : null}
            {distributor.TelephoneOne ? (
              <p className="my-1 text-sm dark:text-grey-300">
                <FontAwesomeIcon
                  className="mr-2 text-blue"
                  icon={["fas", "phone"]}
                />{" "}
                {distributor.TelephoneOne}
              </p>
            ) : null}
            {distributor.EmailOne ? (
              <p className="my-1 text-sm dark:text-grey-300">
                <FontAwesomeIcon
                  className="mr-2 text-blue"
                  icon={["fas", "envelope"]}
                />{" "}
                <a
                  className="hover:underline"
                  href={`mailto:${distributor.EmailOne}`}
                >
                  {distributor.EmailOne}
                </a>
              </p>
            ) : null}
            {distributor.ContactTwo ? (
              <p className="my-1 mt-4 text-sm dark:text-grey-300">
                <FontAwesomeIcon
                  className="mr-2 text-blue"
                  icon={["fas", "user"]}
                />{" "}
                {distributor.ContactTwo}
              </p>
            ) : null}
            {distributor.TelephoneTwo ? (
              <p className="my-1 text-sm dark:text-grey-300">
                <FontAwesomeIcon
                  className="mr-2 text-blue"
                  icon={["fas", "phone"]}
                />{" "}
                {distributor.TelephoneTwo}
              </p>
            ) : null}
            {distributor.EmailTwo ? (
              <p className="my-1 text-sm dark:text-grey-300">
                <FontAwesomeIcon
                  className="mr-2 text-blue"
                  icon={["fas", "envelope"]}
                />{" "}
                <a
                  className="hover:underline"
                  href={`mailto:${distributor.EmailTwo}`}
                >
                  {distributor.EmailTwo}
                </a>
              </p>
            ) : null}
            {distributor.ContactThree ? (
              <p className="my-1 mt-4 text-sm dark:text-grey-300">
                <FontAwesomeIcon
                  className="mr-2 text-blue"
                  icon={["fas", "user"]}
                />{" "}
                {distributor.ContactThree}
              </p>
            ) : null}
            {distributor.TelephoneThree ? (
              <p className="my-1 text-sm dark:text-grey-300">
                <FontAwesomeIcon
                  className="mr-2 text-blue"
                  icon={["fas", "phone"]}
                />{" "}
                {distributor.TelephoneThree}
              </p>
            ) : null}
            {distributor.EmailThree ? (
              <p className="my-1 text-sm dark:text-grey-300">
                <FontAwesomeIcon
                  className="mr-2 text-blue"
                  icon={["fas", "envelope"]}
                />{" "}
                <a
                  className="hover:underline"
                  href={`mailto:${distributor.EmailThree}`}
                >
                  {distributor.EmailThree}
                </a>
              </p>
            ) : null}
            {distributor.ContactFour ? (
              <p className="my-1 mt-4 text-sm dark:text-grey-300">
                <FontAwesomeIcon
                  className="mr-2 text-blue"
                  icon={["fas", "user"]}
                />{" "}
                {distributor.ContactFour}
              </p>
            ) : null}
            {distributor.TelephoneFour ? (
              <p className="my-1 text-sm dark:text-grey-300">
                <FontAwesomeIcon
                  className="mr-2 text-blue"
                  icon={["fas", "phone"]}
                />{" "}
                {distributor.TelephoneFour}
              </p>
            ) : null}
            {distributor.EmailFour ? (
              <p className="my-1 text-sm dark:text-grey-300">
                <FontAwesomeIcon
                  className="mr-2 text-blue"
                  icon={["fas", "envelope"]}
                />{" "}
                <a
                  className="hover:underline"
                  href={`mailto:${distributor.EmailFour}`}
                >
                  {distributor.EmailFour}
                </a>
              </p>
            ) : null}
          </motion.div>
        ))}
      </div>
      <img
        className="absolute object-cover w-full transform -translate-y-1/2 opacity-5 top-1/2"
        src={map}
        alt="map"
      />
    </div>
  );
}
