import React from "react";
import { Link } from "react-router-dom";
import { useEffect, useState, useRef } from "react";
import { motion } from "framer-motion";
import { fadeInLeft, fadeInRight, slow } from "./components/Animations.js";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useTranslation } from 'react-i18next';

export default function Search(props) {
  const [videos, setVideo] = useState([]);
  const [items, setItems] = useState([]);

  const { t } = useTranslation(['common', 'videos']);

  const isMountedRef = useRef(null);

  const [q, setQ] = useState("");
  const [searchParam] = useState(["title", "content"]);

  useEffect(() => {
    isMountedRef.current = true;
    const videos = t('videos', { ns: 'videos', returnObjects: true });
    setVideo(videos);
    fetch(videos).then((result) => {
      if(isMountedRef.current){
        setItems(result);
      }
    });
    return () => isMountedRef.current = false;
  }, [t]);

  useEffect(() => {
    isMountedRef.current = true;
    if(isMountedRef.current){
      const input = document.querySelector("input");
      input.focus();
    }
    return () => isMountedRef.current = false;
  }, []);

  useEffect(() => {
    document.title = t('search') + " | Nikkiso Aquarius+";
  });

  function search(items) {
    return videos.filter((video) => {
      return searchParam.some((newItem) => {
        if (video[newItem]) {
          return (
            video[newItem].toString().toLowerCase().indexOf(q.toLowerCase()) >
            -1
          );
        } else {
          return false;
        }
      });
    });
  }

  return (
    <div className="w-full h-full px-6 md:px-8">
      <div className="flex flex-col justify-between py-6 mb-2 md:flex-row md:items-center">
        <motion.h1
          initial="initial"
          animate="in"
          exit="out"
          variants={fadeInLeft}
          transition={slow} className="mb-4 text-xl text-transparent bg-clip-text bg-gradient-to-r from-blue to-red md:text-2xl xl:text-3xl md:mb-0">
          {t('search')}
        </motion.h1>

        <motion.div
          initial="initial"
          animate="in"
          exit="out"
          variants={fadeInRight}
          transition={slow} className="w-full p-px rounded-full search-wrapper md:w-1/4 bg-gradient-to-r from-blue to-red">
          <label htmlFor="search-form">
            <input
              type="search"
              name="search-form"
              id="search-form"
              className="w-full px-4 py-2 rounded-full search-input dark:text-grey-300 bg-grey dark:bg-grey-900 focus:outline-none"
              placeholder={t('search')}
              value={q}
              /*
                // set the value of our useState e
                //  anytime the user types in the search box
                */
              onChange={(e) => setQ(e.target.value)}
            />
          </label>
        </motion.div>
      </div>
      <div className="flex flex-wrap" style={{ display: q ? "flex" : "none" }}>
        {search(items).map((item, index) => (
          <Link
            to={`/video/${item.slug}`}
            className="flex items-center w-full p-4 mb-4 transition duration-200 border rounded md:mb-8 border-grey-200 dark:border-grey-800 md:p-8 hover:bg-grey-100 dark:hover:bg-grey-950 hover:shadow-lg"
            key={item.id}
          >
            <div className="relative hidden w-2/12 mr-8 md:block">
              <div className="absolute inset-0 overflow-hidden rounded opacity-50 bg-gradient-to-br from-blue to-red"></div>
              <div className="relative overflow-hidden">
                <FontAwesomeIcon
                  className="absolute z-50 text-3xl text-white bottom-2 left-2"
                  icon={["fas", "play-circle"]}
                />
                <div className="absolute inset-0 z-40 rounded opacity-50 bg-gradient-to-br from-blue to-red"></div>
                <img className="rounded" src={item.poster} alt={item.title} />
              </div>
            </div>
            <h3 className="flex-grow text-sm truncate md:text-md dark:text-grey-300">{item.title}</h3>
            <div
              className="relative z-10 inline-block px-4 py-2 ml-4 text-xs font-semibold text-white rounded button bg-blue md:text-base"
              to={`/video/${item.slug}`}
            >
              {t('view')}
            </div>
          </Link>
        ))}
      </div>
    </div>
  );
}
