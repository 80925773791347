import { useState } from "react";
import { Link } from "react-router-dom";
import { motion } from "framer-motion";
import { fadeInDown, slow } from "../components/Animations.js";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useTranslation } from 'react-i18next';
import MarkdownContainer from "../components/MarkdownContainer";

export default function ModalVideo() {
  const [isVisible, setIsVisible] = useState(true);
  const { t } = useTranslation(['common', 'videos']);

  return (
    <div
      className="modal fixed top-0 left-0 w-full h-screen backdrop-filter backdrop-blur-sm"
      style={{ display: isVisible ? "block" : "none" }}
    >
      <div className="absolute w-11/12 md:w-7/12 z-50 top-8 md:top-1/2 left-1/2 transform -translate-x-1/2 md:-translate-y-1/2">
        <motion.div
          initial="initial"
          animate="in"
          exit="out"
          variants={fadeInDown}
          transition={slow} className="rounded p-12 pb-6 shadow-xl bg-white dark:bg-grey-900">
          <h3 className="mb-4 text-xl text-blue">{t('disclaimerTitle')}</h3>
          <div className="text-xs md:text-sm text-justify dark:text-grey-300">
            <MarkdownContainer markdown={t('managementDisclaimerText')} />
          </div>
          <button
            className="button bg-blue text-white text-sm py-2 px-4 inline-block font-semibold relative z-10 rounded focus:outline-none transition mt-2 mr-4"
            onClick={() => setIsVisible(false)}
          >
            <FontAwesomeIcon
              className="cursor-pointer mr-2"
              icon={["fas", "check-circle"]}
            />{" "}
            {t('disclaimerButton')}
          </button>
          <Link
            to="/"
            className="button bg-red text-white text-sm py-2 px-4 inline-block font-semibold relative z-10 rounded focus:outline-none transition mb-6"
          >
            <FontAwesomeIcon
              className="cursor-pointer mr-2"
              icon={["fal", "times"]}
            />{" "}
            {t('disclaimerDecline')}
          </Link>
        </motion.div>
      </div>
    </div>
  );
}
