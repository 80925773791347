import React from 'react';
import { ThemeContext } from './themeContext';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTranslation } from 'react-i18next';

const Toggle = () => {
  const { theme, setTheme } = React.useContext(ThemeContext);
  const { t } = useTranslation(['common', 'videos']);

  return (
    <div className="transition duration-500 ease-in-out rounded-full p-2">
      {theme === 'dark' ? (
        <div className="cursor-pointer dark:text-white" onClick={() => setTheme(theme === 'dark' ? 'light' : 'dark')}>
          <FontAwesomeIcon className="text-blue mr-2" icon={['fas', 'sun']} /> {t('lightMode')}</div>
      ) : (
        <div className="cursor-pointer dark:text-white"
          onClick={() => setTheme(theme === 'dark' ? 'light' : 'dark')}>
          <FontAwesomeIcon className="text-blue mr-2" icon={['fas', 'moon']} /> {t('darkMode')}</div>
      )}
    </div>
  );
};

export default Toggle;