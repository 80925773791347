import { useEffect } from "react";
import { Link } from 'react-router-dom';
import { motion } from "framer-motion";
import { fadeInLeft, fadeInUp, slow } from "../components/Animations.js";
import ConfigurationsImg from '../assets/img/aquarius-system@2x.jpg';
import { useTranslation } from 'react-i18next';
import MarkdownContainer from "../components/MarkdownContainer";

export default function Configurations(props) {
	const { t } = useTranslation(['common', 'videos', 'configurations']);

  useEffect(() => {
    document.title = t('configurations') + " | Nikkiso Aquarius+";
  });

	return (
		<section className="flex flex-wrap md:flex-1 border-t border-grey-200 dark:border-grey-800">
			<div className="w-full md:w-8/12 lg:w-3/4 p-6 md:p-8">
				<motion.h1
					initial="initial"
					animate="in"
					exit="out"
					variants={fadeInLeft}
					transition={slow}
					className="bg-clip-text text-transparent bg-gradient-to-r from-blue to-red text-xl md:text-2xl xl:text-3xl leading-tight mb-6">{t('configurations')}</motion.h1>
				<div className="flex flex-col textContent">
					<section className="section" id="guide">
						<motion.img initial="initial"
							animate="in"
							exit="out"
							variants={fadeInUp}
							transition={slow} className="w-full md:w-3/4 mx-auto mt-12 rounded" src={ConfigurationsImg} alt="Main Screen" />
					</section>
				</div>
			</div>
			<div className="w-full md:w-4/12 lg:w-1/4 md:h-full p-6 md:p-8 bg-grey-100 border-l border-grey-200 dark:border-grey-800 dark:bg-grey-950 dark:text-grey-300">
				<div className="sticky top-10 text-xs xl:text-sm">

					<h3 className="mb-4">{t('menu')}</h3>
					<nav className="mb-8">
						<ul className="nav leading-tight list-none">
							<li className="mb-2"><Link className="active" to="/configurations">{t('aquarius')}</Link></li>
							<li className="mb-2"><Link to="/treatment/main-screen">{t('main')}</Link></li>
							<li className="mb-2"><Link to="/treatment/programming-screen">{t('programming')}</Link></li>
							<li className="mb-2"><Link to="/treatment/programming-guide">{t('programmingGuide')}</Link></li>
							<li className="mb-2"><Link to="/treatment/management">{t('rca')}</Link></li>
						</ul>
					</nav>

					<h3 className="mb-4">Labelled Layout of Main Screen</h3>
					<ol className="mb-12">
						<MarkdownContainer markdown={t('configurationBullets', { ns: 'configurations', returnObjects: true })} />
					</ol>
				</div>
			</div>
		</section>
	)
}
