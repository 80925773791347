import React, { Component } from "react";
import { Route } from 'react-router-dom';
import './assets/Sass/App.scss';
import { library } from '@fortawesome/fontawesome-svg-core';
import { faBars, faSearch, faGlobe, faHeart as falFaHeart, faHome, faInfoCircle, faSlidersVSquare, faTimes, faArrowLeft, faArrowRight, faTrafficLightStop, faTrafficLightSlow, faTrafficLightGo, faCommentSmile } from '@fortawesome/pro-light-svg-icons';
import { faPlayCircle, faArrowCircleLeft, faArrowCircleRight, faMoon, faSun, faBuilding, faUser, faTimesCircle, faPhone, faEnvelope, faHeart as fasFaHeart, faExclamationCircle, faCheckCircle, faAngleDown } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import Menu from './Menu';
import Header from './Header';
import VideoContainer from './Video';
import Loader from './Loader';
import Types from './Types';
import Support from './Support';
import Saved from './Saved';
import Search from './Search';
import FeedbackButton from './components/FeedbackButton';

import Configurations from './configurations/Configurations';

import MainScreen from './treatment/MainScreen';
import Programming from './treatment/Programming';
import ProgrammingGuide from './treatment/ProgrammingGuide';
import Management from './treatment/Management';

import ModalHome from './modals/ModalHome';
import ModalManagement from './modals/ModalManagement';

import { Offline } from "react-detect-offline";

import TagManager from 'react-gtm-module'

const tagManagerArgs = {
    gtmId: 'GTM-NJXZ59N'
}

TagManager.initialize(tagManagerArgs)

library.add(faBars, faSearch, faGlobe, faPlayCircle, faArrowCircleLeft, faArrowCircleRight, falFaHeart, fasFaHeart, faHome, faInfoCircle, faSlidersVSquare, faMoon, faSun, faTimes, faTimesCircle, faArrowLeft, faArrowRight, faBuilding, faUser, faPhone, faEnvelope, faExclamationCircle, faCheckCircle, faTrafficLightStop, faTrafficLightSlow, faTrafficLightGo, faAngleDown, faCommentSmile);

class App extends Component {

	state = { showing: true };

	constructor(props) {
		super(props);
		this.state = {
			isMenuOpen: false,
		}
		this.toggleMenu = this.toggleMenu.bind(this);
	}

	toggleMenu() {
		this.setState({ isMenuOpen: !this.state.isMenuOpen })
	}

	render() {
		const { showing } = this.state;

		return (
			<div className={`App flex flex-col relative h-full ${this.state.isMenuOpen === true ? 'overflow-x-hidden' : ''}`}>
				<Offline>
					<div className='bg-blue text-xs text-center w-full text-white p-1'>
						<p>You are offline. Please connect to the internet for the best experience.</p>
					</div>
				</Offline>
				<Header isMenuOpen={this.state.isMenuOpen} onMenuToggle={this.toggleMenu} onEnToggle={this.setEn} onEsToggle={this.setEs} />
				<Menu isMenuOpen={this.state.isMenuOpen} onMenuToggle={this.toggleMenu} />
				<Route path='/' exact>
					<main className={`w-screen h-full flex flex-col ${this.state.isMenuOpen === true ? 'menu-open' : ''}`}>
						<Loader />
					</main>
				</Route>
				<Route path='/video/:slug' modal={false}>
					<main className={`w-screen md:h-full flex flex-col ${this.state.isMenuOpen === true ? 'menu-open' : ''}`}>
						<VideoContainer />
					</main>
				</Route>
				<Route path='/videos' exact modal={true}>
					<ModalHome onEnToggle={this.setEn} onEsToggle={this.setEs} />
					<main className={`w-screen md:h-full flex flex-col ${this.state.isMenuOpen === true ? 'menu-open' : ''}`}>
						<Types />
					</main>
					
					{ showing 
						? null
						: <div className="bg-blue shadow-lg rounded fixed bottom-3 p-2 text-sm text-white left-1/2 transform -translate-x-1/2 md:hidden">
								<p>Save to your home screen <button onClick={() => this.setState({ showing: !showing })}><FontAwesomeIcon icon={["fas", "times-circle"]} /></button></p>
								<svg className="absolute z-10 w-6 h-6 text-blue transform left-1/2 -bottom-4 -translate-x-1/2 fill-current stroke-current" width="8" height="8">
									<rect x="12" y="-10" width="8" height="8" transform="rotate(45)" />
								</svg>
							</div>
					}
				</Route>
				<Route path='/support'>
					<main className={`w-screen flex flex-col flex-grow h-screen ${this.state.isMenuOpen === true ? 'menu-open' : ''}`}>
						<Support />
					</main>
				</Route>
				<Route path='/saved-videos'>
					<main className={`w-screen md:h-full flex flex-col ${this.state.isMenuOpen === true ? 'menu-open' : ''}`}>
						<Saved />
					</main>
				</Route>
				<Route path='/search'>
					<Search />
				</Route>
				<Route path='/configurations'>
					<main className={`w-screen md:h-full flex flex-col ${this.state.isMenuOpen === true ? 'menu-open' : ''}`}>
						<Configurations />
					</main>
				</Route>
				<Route path='/treatment/main-screen'>
					<main className={`w-screen md:h-full flex flex-col ${this.state.isMenuOpen === true ? 'menu-open' : ''}`}>
						<MainScreen />
					</main>
				</Route>
				<Route path='/treatment/programming-screen'>
					<main className={`w-screen md:h-full flex flex-col ${this.state.isMenuOpen === true ? 'menu-open' : ''}`}>
						<Programming />
					</main>
				</Route>
				<Route path='/treatment/programming-guide'>
					<main className={`w-screen md:h-full flex flex-col ${this.state.isMenuOpen === true ? 'menu-open' : ''}`}>
						<ProgrammingGuide />
					</main>
				</Route>
				<Route path='/treatment/management'>
					<ModalManagement />
					<main className={`w-screen md:h-full flex flex-col ${this.state.isMenuOpen === true ? 'menu-open' : ''}`}>
						<Management />
					</main>
				</Route>
				<FeedbackButton />
			</div>
		);
	}
}

export default App;
