import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Toggle from './components/themeToggle';
import { useTranslation } from 'react-i18next';

export default function Menu(props) {
	const { t } = useTranslation(['common', 'videos']);
	const isMenuOpen = props.isMenuOpen;

	return(
		<div className={`bg-white dark:bg-grey-950 p-8 flex flex-col justify-between sidebar-menu shadow-2xl ${isMenuOpen === true ? 'menu-open' : ''}`}>
			<h4 className="font-bold uppercase dark:text-white">{t('menu')}</h4>
			<ul className="list-none pl-0 mb-0">
				<li className="text-base xl:text-lg my-4"><Link className="cursor-pointer dark:text-white" to="/videos" onClick={props.onMenuToggle}><FontAwesomeIcon className="text-blue mr-4" icon={['fal', 'home']} /> {t('homeButton')}</Link></li>
				<li className="text-base xl:text-lg my-4"><Link className="cursor-pointer dark:text-white" to="/configurations" onClick={props.onMenuToggle}><FontAwesomeIcon className="text-blue mr-4" icon={['fal', 'sliders-v-square']} /> {t('configurations')}</Link></li>
				<li className="text-base xl:text-lg my-4"><Link className="cursor-pointer dark:text-white" to="/saved-videos" onClick={props.onMenuToggle}><FontAwesomeIcon className="text-blue mr-4" icon={['fal', 'heart']} /> {t('savedVideos')}</Link></li>
				<li className="text-base xl:text-lg my-4"><Link className="cursor-pointer dark:text-white" to="/support" onClick={props.onMenuToggle}><FontAwesomeIcon className="text-blue mr-4" icon={['fal', 'info-circle']} /> {t('support')}</Link></li>
				<li className="text-base xl:text-lg my-4"><a className="cursor-pointer dark:text-white" href="https://form.jotform.com/213485203221040" target="_blank" rel="noreferrer"><FontAwesomeIcon className="text-blue mr-4" icon={['fal', 'comment-smile']} /> {t('feedback')}</a></li>
				<li className="text-base xl:text-lg my-4 mb-0"><a className="cursor-pointer dark:text-white" href="https://nikkisomedical.com/care-continuous-acute-renal-education" target="_blank" rel="noreferrer"><FontAwesomeIcon className="text-blue mr-4" icon={['fal', 'arrow-left']} /> {t('care')}</a></li>
			</ul>
			<Toggle />
		</div>
	)
}
